<template>
  <div>
    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="row">
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Project Code - Nickname</label>

            <b-form-select
              v-model="filterProject.nickname"
              :options="formattedProjectOptions"
              size="lg"
            ></b-form-select>
          </div>
          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Change Management Number</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Change Management Number"
              v-model="cr_number"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Title</label>

            <b-form-input
              id="input-large"
              size="lg"
              placeholder="Title"
              v-model="nickname"
            ></b-form-input>
          </div>

          <div class="col-md-6 py-2 col-sm-12">
            <label for="input-large">Type</label>

            <b-select
              :options="formattedTypeOptions"
              size="lg"
              v-model="type"
            ></b-select>
          </div>
        </div>

        <div class="d-flex justify-content-end mt-5">
          <button class="btn btn-primary btn-md" @click.prevent="filter">
            <i class="flaticon2-search-1"></i> Filter
          </button>
        </div>
      </div>
    </div>

    <div class="card card-custom p-5 gutter-b">
      <div class="card-body p-0">
        <div class="mb-3 text-success">
          <button
            class="btn btn-success btn-md"
            @click.prevent="add_change_management"
          >
            <i class="flaticon-add"></i> Add Change Management
          </button>
        </div>

        <complete-table
          :loading="false"
          :data="this.$store.getters.getterChangeManagement"
        >
          <template #header>
            <tr class="text-left">
              <th style="min-width: 150px">Change Management Number</th>
              <th style="min-width: 150px">Title</th>
              <th style="min-width: 200px">Project</th>
              <th style="min-width: 100px">Type</th>
              <th style="min-width: 100px">Action</th>
            </tr>
          </template>

          <template #defaultBody="{item, i}">
            <tr v-bind:key="i">
              <td>
                <span
                  class="ml-5 text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.cr_number }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.title }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.project.name }}
                </span>
              </td>

              <td>
                <span
                  class="text-dark-75 font-weight-bolder d-block font-size-lg"
                >
                  {{ item.type ? item.type.name : "-" }}
                </span>
              </td>

              <td class="d-flex mt-2">
                <!-- <a
                                        class="btn btn-light-success font-weight-bolder font-size-sm"
                                        @click="edit(item.id)"
                                    >
                                        <div
                                            class="text-centerp d-none"
                                            v-bind:id="'loading-change-' + item.id"
                                        >
                                            <b-spinner variant="primary" style="width: 1rem; height: 1rem;" label="Spinning"></b-spinner>
                                        </div>
                                        <div
                                            class="text-center"
                                            v-bind:id="'edit-text-change-' + item.id"
                                        >
                                            <i class="flaticon-edit"></i>
                                        </div>
                                    </a> -->
                <router-link
                  :to="{
                    name: 'change-management-detail',
                    params: { id_change_management: item.id }
                  }"
                >
                  <div class="text-center">
                    <button class="btn btn-light-success">
                      <i class="flaticon-edit"></i>
                    </button>
                  </div>
                </router-link>
                <a
                  class="btn btn-light-danger font-weight-bolder font-size-sm ml-2"
                  @click="open_model_delete(item.id)"
                >
                  <i class="flaticon-delete"></i>
                </a>
              </td>
            </tr>
          </template>
        </complete-table>
      </div>
    </div>
    <b-modal ref="delete" hide-footer title="Warning">
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-12 py-2 col-sm-12 text-left">
            <label for="input-large"
              >Are You Sure Want To Delete Change Management</label
            >
          </div>
        </div>
        <div class="d-flex mt-2">
          <b-button
            :disabled="loading"
            class="btn btn-success mr-2"
            @click="deleteManagement"
            block
          >
            Delete
          </b-button>

          <b-button
            class="btn btn-secondary mt-0"
            block
            @click="closeDeleteModal"
            :disabled="loading"
          >
            Cancel
          </b-button>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {
  arrayMoreThanOne,
  formattedOptions
} from "../../../core/helper/array-validation.helper";
import {
  GET_CHANGE_MANAGEMENT,
  GET_DETAIL_CHANGE,
  DELETE_MANAGEMENT
} from "@/core/services/store/change-management.module";
import { GET_PROJECT_DETAIL } from "../../../core/services/store/project.module";
import { mapState } from "vuex";
import CompleteTable from "../../content/widgets/advance-table/CompleteTable";
import ApiService from "../../../core/services/api.service";
import { showToast } from "../../../core/helper/toast.helper";

export default {
  name: "change-management",
  components: { CompleteTable },
  data() {
    return {
      cr_number: "",
      nickname: "",
      type: null,
      currentItemDelete: null,
      options: {
        statuses: [
          { value: null, text: "Choose Status" },
          { value: "open", text: "Open" },
          { value: "close", text: "Close" }
        ],
        types: []
      },
      filterProject: {
        id_project: null,
        nickname: null,
        station: null,
        scope: null,
        jobdesk: ["design", "admin"],
        code: null
      },
      data: {
        projects: [
          {
            cr_number: "LR98123",
            title: "Cr Title",
            project: {
              name:
                "PEMBANGUNAN SISTEM PERSINYALAN DAN TELEKOMUNIKASI JALUR KERETA API ANTARA MANDALLE - PALANRO LINTAS MAKASAR-PARE PARE (MYC 2019-2020)"
            }
          }
        ]
      }
    };
  },
  methods: {
    getChangeManagementType() {
      ApiService.setHeader();

      ApiService.query("change/management/type")
        .then(response => {
          this.options.types = response.data.data;
        })
        .catch(() => {
          showToast("Error", "Failed get change management type", "danger");
        });
    },

    filter() {
      var search = {
        title: this.nickname,
        cr_number: this.cr_number,
        type_code: this.type,
        code_project: this.filterProject.nickname
      };
      this.$store.dispatch(GET_CHANGE_MANAGEMENT, search);
    },

    add_change_management() {
      var url = "/project/change/management/create";
      this.$router.push(url);
    },
    open_model_delete(id) {
      this.currentItemDelete = id;
      this.$refs["delete"].show();
    },
    deleteManagement() {
      this.$store
        .dispatch(DELETE_MANAGEMENT, {
          id: this.currentItemDelete
        })
        .then(() => {
          this.closeDeleteModal();
        });
    },
    openDialog() {
      this.dialog = true;
    },
    closeDeleteModal() {
      this.$refs["delete"].hide();
    },

    async edit(id) {
      var loading = document.getElementById("loading-change-" + id);
      var text = document.getElementById("edit-text-change-" + id);

      loading.classList.remove("d-none");
      text.classList.add("d-none");
      await this.$store.dispatch(GET_DETAIL_CHANGE, { id: id });
      loading.classList.add("d-none");
      text.classList.remove("d-none");
      this.$router.push("/project/change/management/edit");
    },
    getProjects() {
      this.$store.dispatch(GET_PROJECT_DETAIL, {
        jobdesk: "design",
        params: this.filterProject.jobdesk
      });
    }
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Change Management" }]);

    this.$store.dispatch(GET_CHANGE_MANAGEMENT, { nickname: "", cr: "" });
    this.getProjects();
    this.getChangeManagementType();
  },
  computed: {
    ...mapState({
      projects: state => state.projectDetails.projectDetails?.data
    }),
    formattedTypeOptions() {
      return formattedOptions(this.options.types);
    },
    formattedProjectOptions() {
      let projects = [];

      if (arrayMoreThanOne(this.projects)) {
        projects = this.projects.map(project => {
          return {
            value: project.code,
            text: project.code + " - " + project.nickname
          };
        });
      }

      return [{ value: null, text: "Choose Nickname" }, ...projects];
    }
  }
};
</script>

<style></style>
